import React from 'react'
import AutoForm from '@components/AutoForm'
import Button from '@components/Button'
import fields from '@components/fields'
import nl2br from '@helpers/misc/nl2br'
import useRef from '@hooks/useRef'
import useState from '@hooks/useState'
import useWidth from '@hooks/useWidth'
import Container from '@packages/justo-parts/lib/components/Container'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import {Field} from 'simple-react-form'

import Image from '../shared/Image'

import styles from './styles.module.css'

export default function ContactForm({
  image,
  title,
  text,
  additionalFields,
  id: componentId,
}) {
  const form = useRef(null)
  const [sent, setSent] = useState(false)
  const viewportWidth = useWidth()
  const isSmall = viewportWidth <= 768
  const imageHeight = 100
  const imagePadding = 20
  const [email, setEmail] = useState<string>()
  const [emailError, setEmailError] = useState('')

  const hasDiacritics = emailStr => /\p{M}/u.test(emailStr.normalize('NFD'))

  const validateEmailText = email => {
    const emailString = email.trim()
    if (hasDiacritics(emailString)) {
      setEmailError('La dirección de correo no debe llevar signos diacríticos')
    }
  }

  return (
    <Container size="medium">
      <div className={styles.container}>
        {image && (
          <Image
            className={styles.image}
            alt=""
            fileId={image._id}
            height={imageHeight}
            width={isSmall ? 360 : 1600}
            style={{height: `${imageHeight}px`, maxWidth: `calc(100% - ${imagePadding}px)`}}
          />
        )}
        <div className={styles.title}>{title}</div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className={styles.text}>{nl2br(text)}</div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div>
              <AutoForm
                mutation="submitContactForm"
                state={{email}}
                onChange={({email}) => setEmail(email)}
                ref={form}
                omit={['additionalFields']}
                doc={{componentId}}
                onSuccess={() => setSent(true)}>
                <Field
                  fieldName="name"
                  fieldType="name"
                  label="Nombre"
                  placeholder="Escribe tu nombre"
                  type={Text}
                />
                <Field
                  fieldName="email"
                  fieldType="email"
                  label="Email"
                  placeholder="Escribe tu email"
                  type={Text}
                  errorMessage={emailError || ''}
                  onBlur={() => validateEmailText(email)}
                />
                {additionalFields && additionalFields.length > 0 && (
                  <Field
                    fieldName="additionalFields"
                    type={fields['array']}
                    useAccordion={false}
                    showAddButton={false}
                    showRemoveButton={false}
                    showStyle={false}
                    draggable={false}
                    autoAddItem>
                    {additionalFields.map(field => {
                      return (
                        <Field
                          key={field.label}
                          fieldName={`${field.type}-${field.label}`}
                          type={fields[field.type]}
                          placeholder={field.label}
                          label={field.label}
                          options={
                            field.aditionalInfo && field.aditionalInfo.length > 0
                              ? field.aditionalInfo.map(info => ({
                                  label: info,
                                  value: info
                                }))
                              : []
                          }
                          isRange={false}
                        />
                      )
                    })}
                  </Field>
                )}

                <Field fieldName="message" type={fields['textarea']} label="Mensaje" />
              </AutoForm>
              <br />
              {sent ? (
                <div>Tu mensaje fue enviado correctamente</div>
              ) : (
                <Button
                  onClick={() => {
                    form.current.submit()
                  }}>
                  Enviar mensaje
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
