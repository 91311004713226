import React from 'react'
import PropTypes from 'prop-types'
import {ObjectComponent} from 'simple-react-form'

export default class ObjectField extends ObjectComponent {
  static propTypes = {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
    ...ObjectComponent.propTypes,
    style: PropTypes.object
  }

  static defaultProps = {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
    ...ObjectComponent.defaultProps,
    style: {}
  }

  renderLabel() {
    if (!this.props.label) return
    return (
      <div>
        <br />
        <div>
          <b>{this.props.label}</b>
        </div>
        <br />
      </div>
    )
  }

  renderErrorMessage() {
    if (!this.props.errorMessage) return
    return <div style={{color: 'red'}}>{this.props.errorMessage}</div>
  }

  render() {
    return (
      <div style={this.props.style}>
        {this.renderLabel()}
        {this.getChildrenComponents()}
        {this.renderErrorMessage()}
      </div>
    )
  }
}
