import React from 'react'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'
import PropTypes from 'prop-types'

export default function Image({
  alt,
  fileId,
  scale,
  width,
  height,
  resizeMode,
  background,
  format,
  style,
  className,
  isDiv = false,
}) {
  const imageResizeProps = {
    fileId,
    ...(scale && {scale}),
    ...(width && {width}),
    ...(height && {height}),
    ...(resizeMode && {resizeMode}),
    ...(background && {background}),
    ...(format && {format})
  }

  const imageUrl = useResizedImageUrlSsr(imageResizeProps)


  const imageMarkupProps = {
    ...(className && {className}),
    ...(style && {style})
  }

  if (!isDiv) {
    return <img src={imageUrl} alt={alt} {...imageMarkupProps} />
  }

  const divMarkupProps = {
    ...(className && {className}),
    style: {backgroundImage: `url(${imageUrl})`, ...(style && {style})}
  }

  return <div aria-label={alt} {...divMarkupProps} />
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  scale: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  resizeMode: PropTypes.string,
  background: PropTypes.string,
  format: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  isDiv: PropTypes.bool
}
