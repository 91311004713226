import React from 'react'

export default function Nl2br(text) {
  return text.split('\n').map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    )
  })
}
